<template>
  <div class="animated fadeIn">
    <b-form @submit.prevent="login" novalidate autocomplete="off">
      <b-card header-tag="header">
        <div slot="header">
          <h2>Login</h2>
        </div>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="username" class="eden__label__required">Email</label>
              <b-form-input
                id="username"
                ref="username"
                v-model.trim="form.username"
                :state="$v.form.username.$dirty ? !$v.form.username.$error : null"
                aria-describedby="usernameFeedback"
                placeholder="Email"
                autocomplete="nope"
                autocorrect="off"
                autocapitalize="off"></b-form-input>
              <b-form-invalid-feedback id="usernameFeedback">
                This is a required field
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="password" class="eden__label__required">
                Password
              </label>
              <password-toggle-input
                v-model="form.password"
                placeholder="Password"></password-toggle-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12" xl="2" lg="3" md="6">
            <b-form-group>
              <b-button
                type="submit"
                ref="save"
                id="save"
                variant="primary"
                :disabled="!this.form.username || !this.form.password">
                <i class="fa fa-user mr-2"></i>
                Login
              </b-button>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <a :href="azureAuthLink"
               role="button"
               class="btn btn-info">
              <CIcon name="cib-microsoft" class="mr-2"></CIcon>
               Login with Office 365
            </a>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col cols="12">
            <a href="/forgot-password">Forgot password? Click here.</a>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { v4 as uuidv4 } from 'uuid'

export default {
  mixins: [
    validationMixin,
  ],

  data() {
    return {
      form: {
        username: null,
        password: null,
      },
    }
  },

  computed: {
    azureAuthLink() {
      const redirectUri = encodeURIComponent(`${window.location.origin}/login/azuread`)
      const { pkce } = this.$store.getters
      return `/api/auth/azuread/redirect?redirectUri=${redirectUri}&pkce=${pkce}`
    },
  },

  validations: {
    form: {
      username: { required },
      password: { required },
    },
  },

  methods: {
    async login() {
      // Form validation
      this.$v.form.$touch()

      if (this.$v.form.$anyError) {
        this.$toasted.global.error({
          message: 'Please correct errors in form',
        })
        return
      }

      const loader = this.$loading.show()
      try {
        const { data } = await this.$request({
          url: '/api/auth/login',
          method: 'post',
          data: this.form,
        })
        this.$store.commit('authToken', data?.token)

        // Store our app version in local storage
        try {
          const res = await this.$request({
            method: 'get',
            url: '/build_version.json',
            headers: {
              Authorization: `Bearer ${data?.token}`,
            },
            params: {
              __ts: Date.now(),
            },
          })

          console.log(`Stored app version: ${this.$store.getters.appVersion}`)
          console.log(`Deployed app version: ${res.data.version}`)
          this.$store.commit('appVersion', res.data.version)
        } catch (e) {
          this.$log.error('Error logging in user', e)
        }

        // TODO: Router Hijack
        // TODO: Axios Hijack

        // Redirect if we ended up here from a session expire
        try {
          if (this.$store.getters.loginRedirect) {
            const redir = this.$store.getters.loginRedirect
            this.$store.commit('loginRedirect', null)
            return window.location.href = redir
          }
        } catch (e) {
          this.$log.error('Error redirecting user', e)
        }

        this.$router.push('/')
      } catch (e) {
        this.$log.error('Error logging in user', e)
        this.$toasted.global.error({
          message: 'Invalid credentials',
        })
        return
      } finally {
        loader.hide()
      }
    },
  },

  async mounted() {
    this.$store.commit('pkce', uuidv4() +
      Math.random().toString() +
      Date.now().toString())
  },
}
</script>
